
import { format, parseISO } from 'o365.lib.date-fns.js';

const formatCustomDate = function(pFromDate: string, pToDate: string, allDay: boolean){
    if (!pFromDate && !pToDate){
        return "";
    }

    const fromDate = pFromDate ? parseISO(pFromDate) : null;
    const toDate = pToDate ? parseISO(pToDate) : null;

    // TODO Also IncludeYear?
    let dateString = "";

    if ((fromDate && !toDate) || (!fromDate && toDate)){
        const date = fromDate || toDate;
        if (allDay){
            dateString = `${format(date,"EEEE d. MMMM yyyy")}`; // Mandag 14. Juni 2023
        } else {
            dateString = `${format(date,"EEEE d. MMMM yyyy kl HH:mm")}`; // Mandag 14. Juni 2023 kl 20:00
        }
        
        return dateString;
    }

    const isSameDay = sameDay(fromDate,toDate),
            isSameMonth = sameMonth(fromDate,toDate),
            isSameYear = sameYear(fromDate,toDate);

    if (isSameDay && allDay){
        dateString = `${format(fromDate,"EEEE d. MMMM yyyy")}`; // Mandag 14. Juni 2023
    } else if (isSameDay && !allDay){ // Thursday 1. June 2023 kl. 10:00 - 11:27
        dateString = `${format(fromDate,"EEEE d. MMMM yyyy 'kl'. HH:mm")} - ${format(toDate,"HH:mm")}`;//
    } else if (isSameMonth && allDay){ // 14. - 16. juni 2023
        dateString = `${format(fromDate,"d.")} - ${format(toDate,"d. MMMM yyyy")}`;//
    } else if (isSameMonth && !allDay){ // 14. juni kl. 18:00 - 16. juni kl. 20:00 2023
        dateString = `${format(fromDate,"d. MMMM 'kl'. HH:mm")} - ${format(toDate,"d. MMMM 'kl'. HH:mm yyyy")}`;//
    } else if (isSameYear && allDay){ // 7. June - 6. December 2023
        dateString = `${format(fromDate,"d. MMMM")} - ${format(toDate,"d. MMMM yyyy")}`;//
    } else if (isSameYear && !allDay){ // 7. June kl. 11:28 - 6. December kl. 11:36 2023
        dateString = `${format(fromDate,"d. MMMM 'kl'. HH:mm")} - ${format(toDate,"d. MMMM 'kl'. HH:mm yyyy")}`;//
    } else if (!isSameYear && allDay){ // 7. June 2023 - 4. January 2024
        dateString = `${format(fromDate,"d. MMMM yyyy")} - ${format(toDate,"d. MMMM yyyy")}`;//
    } else if (!isSameYear && !allDay){ // 7. June kl. 11:28 2023 - 4. January kl. 11:33 2024
        dateString = `${format(fromDate,"d. MMMM 'kl'. HH:mm yyyy")} - ${format(toDate,"d. MMMM 'kl'. HH:mm yyyy")}`;//
    } else {
        return "invalid date range";
    }
    

    function sameDay(d1, d2) {
        return d1.getFullYear() === d2.getFullYear() &&
            d1.getMonth() === d2.getMonth() &&
            d1.getDate() === d2.getDate();
    }
    
    function sameMonth(d1, d2) {
        return d1.getFullYear() === d2.getFullYear() &&
            d1.getMonth() === d2.getMonth();
    }
    function sameYear(d1, d2) {
        return d1.getFullYear() === d2.getFullYear();
    }

    return dateString;
};

export { formatCustomDate }